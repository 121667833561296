var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"nav"},[_c('router-link',{staticClass:"nav__link",attrs:{"to":{
      name: 'Assets',
      query: Object.assign({}, _vm.$route.query, {nav: 'owned'})
    }}},[_vm._v(" NFTs ")]),_c('router-link',{staticClass:"nav__link",attrs:{"to":{
      name: 'Assets',
      query: Object.assign({}, _vm.$route.query, {nav: 'boxes'})
    }}},[_c('span',{staticClass:"nav__link-label"},[_vm._v(" New ")]),_vm._v(" Mystery Boxes ")]),_c('router-link',{staticClass:"nav__link",attrs:{"to":{
      name: 'Assets',
      query: Object.assign({}, _vm.$route.query, {nav: 'activity'})
    }}},[_vm._v(" Activity ")]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.collapse),expression:"collapse"}],staticClass:"nav__extended"},[_c('span',{staticClass:"nav__extended-placeholder",on:{"click":function($event){return _vm.toggleState()}}},[_vm._v(" More "),_c('md-icon',{class:{
          'md-size-1x': true,
          'nav__extended-icon': true,
          'extended': _vm.extended,
        }},[_vm._v(" expand_more ")])],1),_c('div',{ref:"extendedElem",staticClass:"nav__extended-content"},[_c('span',{staticClass:"\n          nav__extended-placeholder\n          nav__extended-placeholder--upper\n        ",on:{"click":function($event){return _vm.toggleState()}}},[_vm._v(" More "),_c('md-icon',{class:{
          'md-size-1x': true,
          'nav__extended-icon': true,
          'nav__extended-icon--extended': _vm.extended,
        }},[_vm._v(" expand_more ")])],1),_c('div',{staticClass:"nav__extended-link",on:{"click":function($event){return _vm.selectStatusFiltersOnOwned('ALL')}}},[_vm._v(" All ")]),_c('div',{staticClass:"nav__extended-link",on:{"click":function($event){return _vm.selectStatusFiltersOnOwned('ASK')}}},[_vm._v(" On sale ")]),_c('div',{staticClass:"nav__extended-link",on:{"click":function($event){return _vm.selectStatusFiltersOnOwned('AUCTION')}}},[_vm._v(" On Auction ")]),_c('div',{staticClass:"nav__extended-link",on:{"click":function($event){return _vm.selectStatusFiltersOnOwned('ACTIVE_LISTING')}}},[_vm._v(" Active listing ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }