var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('h1',{staticClass:"content__title"},[_vm._v("Mystery Box")]),(_vm.boxInfo)?_c('h1',{class:[
      'content__name',
      ("content__name--type--" + (_vm.boxInfo.name.toLowerCase()))
    ]},[_vm._v(" "+_vm._s(_vm.boxInfo.name)+" ")]):_vm._e(),_c('img',{staticClass:"content__img",attrs:{"src":('https://d1ak9kdvdopy8s.cloudfront.net/' + _vm.cardImage),"alt":""}}),(_vm.boxInfo)?_c('router-link',{staticClass:"content__btn",attrs:{"to":{
      name: 'MysteryBox',
      query: {
        boxId: _vm.boxInfo.id,
        name: _vm.boxInfo.name.toLowerCase()
      }
    }}},[_vm._v(" Open Now ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }